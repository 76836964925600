import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import HeaderPadding from '../layout/HeaderPadding';
import { usePageTitle } from '../hooks/usePageTitle';
import { BlockContent } from '../components/block-content';
import { LanguageContext } from '../layout/Layout';
import { useContext } from 'react';
import SEO from '../components/seo';
import useOGImage from '@hooks/useOGImage';
import useGetBlock from '@hooks/useGetBlock';

export const query = graphql`
  query CustomPageTemplateQuery($id: String!) {
    case: sanityCustomPage(id: { eq: $id }) {
      title {
        nb
        en
      }
      image {
        asset {
          url
        }
      }
      _rawBody
    }
  }
`;

const Page = ({ data: { case: article } }) => {
  const lang = useContext(LanguageContext);
  const title = (article.title && article.title[lang]) || (article.title && article.title['nb']);
  const body = (article._rawBody && article._rawBody[lang]) || (article._rawBody && article._rawBody['nb']) || [];
  const ogImage = useOGImage(article?.image);
  const description = useGetBlock(body);
  usePageTitle(title);
  return (
    <Fragment>
      <SEO {...ogImage} description={description} title={title} />
      <HeaderPadding />
      <BlockContent blocks={body} />
    </Fragment>
  );
};

export default Page;
